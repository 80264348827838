import React from "react";
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
	const socialData = [{ link: "https://www.instagram.com/neunziger.media/", name: "Instagram" }];
	return (
		<div className={dsnCN("container-content  d-flex flex-column justify-content-center", className)}>
			<div className='nav__info'>
				<div className='nav-content'>
					<h5 className='sm-title-block mb-10'>Agentur</h5>
					<p>
						neunziger media <br />
						Frankfurter Straße 3 <br /> 65779 Kelkheim
					</p>
				</div>
				<div className='nav-content mt-30'>
					<h5 className='sm-title-block mb-10'>Kontakt</h5>
					<p className='links over-hidden mb-1'>
						<a
							className='link-hover'
							href='tel:069 / 247 501 360'
							data-hover-text='069 / 247 501 360'
						>
							069 / 247 501 360
						</a>
					</p>
					<p className='links over-hidden'>
						<a
							className='link-hover'
							href='mailto:hello@neunziger.media'
							data-hover-text='hello@neunziger.media'
						>
							hello@neunziger.media
						</a>
					</p>
					<p className='links over-hidden'>
						<a
							className='link-hover'
							href='mailto:kontakt@neunziger.media'
							data-hover-text='kontakt@neunziger.media'
						>
							kontakt@neunziger.media
						</a>
					</p>
				</div>
			</div>
			<div className='nav-social nav-content mt-30'>
				<div className='nav-social-inner p-relative'>
					<h5 className='sm-title-block mb-10'>Social Media</h5>
					<ul>
						{socialData.map((item, index) => (
							<li key={index}>
								<a href={item.link} target='_blank' rel='nofollow noopener noreferrer'>
									{item.name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default MenuContent;
