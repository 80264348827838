import React from "react";
import Navbar, { Nav } from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";
import { useLocation } from "react-router-dom";

const menuContent = [
	{ name: "startseite", to: "/" },
	{ name: "portfolio", to: "/portfolio" },
	{
		name: "leistungen",
		dropdownMenu: [
			{ name: "Design", to: "/design" },
			{ name: "Strategie", to: "/strategie" },
			{ name: "Entwicklung", to: "/entwicklung" },
			{ name: "Motion", to: "/motion" },
		],
	},

	{ name: "neunziger", to: "/neunziger" },
	{ name: "kontakt", to: "/kontakt" },
];

const DroowMenu = ({ hamburger }) => {
	const location = useLocation();

	let $key = 0;
	const getSubMenu = (items) => {
		$key++;
		if (items.dropdownMenu) {
			return (
				<Nav.Dropdown name={items.name} key={$key}>
					{items.dropdownMenu.map((item) => getSubMenu(item))}
				</Nav.Dropdown>
			);
		}
		return (
			<Nav.Link
				to={items.to}
				key={$key}
				style={location.pathname === items.to ? { color: "#274f5b" } : {}}
			>
				{items.name}
			</Nav.Link>
		);
	};

	return (
		<Navbar hamburger={hamburger}>
			<Navbar.Brand to={"/"}>
				<Brand width='80px' height='26px' />
			</Navbar.Brand>

			<Navbar.Collapse cover='Navigation'>
				<Nav>{menuContent.map((item) => getSubMenu(item))}</Nav>
				<MenuContent className='section-margin' />
			</Navbar.Collapse>
		</Navbar>
	);
};

DroowMenu.defaultProps = {
	hamburger: false,
};

export default DroowMenu;
