import React, { useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import useEffectLocation from "../hooks/useEffectLocation";

import "./style.scss";

const Scrollbar = React.lazy(() => import("smooth-scrollbar"));

const Home = React.lazy(() => import("../views/Home"));
const Portfolio = React.lazy(() => import("../views/Portfolio"));
const Project = React.lazy(() => import("../views/Project"));

const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/Contact"));
const Impressum = React.lazy(() => import("../views/Impressum"));

const Design = React.lazy(() => import("../views/leistungen/Design"));
const Strategie = React.lazy(() => import("../views/leistungen/Strategie"));
const Entwicklung = React.lazy(() => import("../views/leistungen/Entwicklung"));
const Motion = React.lazy(() => import("../views/leistungen/Motion"));

const Error = React.lazy(() => import("../views/Error"));

const Router = () => {
	const [transPage, setTransPage] = useState("in");
	const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector((state) => state.scrollbar);

	const location = useEffectLocation((l) => {
		setTransPage("out");
	});

	const onAnimateEnd = () => {
		if (transPage !== "out") return;
		scrollbar.current?.scrollTo(0, 0, 0);
		window.scrollTo({ left: 0, top: 0 });
		setTransPage("in");
	};

	return (
		<div
			id='dsn-content'
			className={`dsn-transition-page dsn-animate-${transPage}`}
			onAnimationEnd={onAnimateEnd}
		>
			<Suspense fallback={<div className='background-main h-100-v' />}>
				<Routes location={location} basename='/'>
					<Route path='/' element={<Home />} />
					<Route path='/portfolio' element={<Portfolio />} />
					<Route path='/portfolio/:slug' element={<Project />} />
					<Route path='/neunziger' element={<About />} />

					<Route path='/design' element={<Design />} />
					<Route path='/strategie' element={<Strategie />} />
					<Route path='/entwicklung' element={<Entwicklung />} />
					<Route path='/motion' element={<Motion />} />

					<Route path='/kontakt' element={<Contact />} />
					<Route path='/impressum' element={<Impressum />} />
					<Route path='*' element={<Error />} />
				</Routes>
			</Suspense>
		</div>
	);
};

export default React.memo(Router);
